<template>
    <Header/>
        <Banner name="Blog" message="Check our latest updates" />
        <BlogComponent/>
    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import BlogComponent from '@/components/home/Blog.vue';
export default{
    name:"Blogs",
    components:{
        Header,Banner,Footer,BlogComponent
    }
}
</script>